export const section2 = {
    paragraphs: {
    title: "RDB Studio - Movie Creators",
    paragraph: "To właśnie tutaj tworzymy niepowtarzalne ujęcia zamieniamy je w filmy i reklamy. ",
    showreelUrl: "",
    paragraph2: "Dzięki odpowiedniemu doświadczeniu i kreatywności tworzymy to co pozwala markom komunikować się z grupą docelową. Zaznacz swoją pozycję w miejscach niedostępnych do tej pory.",
    paragraph3: "Świat video otwiera drogi do osób, które nigdy nie zdołałyby usłyszeć Twojego glosu.",
    animation: "",
    downTitle: "Wybierz swój sposób, a my go zekranizujemy!",
    downName: "RDB STUDIO",
    },
}

