import React from 'react'

import { colors, margins, fontSizes, breakpoint } from '../../cms/style'
// import stringEnter from '../../utils/stringEnter.util'
import { section2 } from '../../cms/data/rdb-media/data'



export default function S2() {

  return (
    <section css={styles.mainSection}>
      <div css={styles.info}>
        <h2>{section2.paragraphs.paragraph}</h2>
        <div css={styles.info.showreel}>
          <iframe css={{width: '100%', height: '100%'}} src="https://www.youtube.com/embed/r4Iw1b-ebm8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <p>{section2.paragraphs.paragraph2}</p>
        <img src="" alt="" />

        <p>{section2.paragraphs.paragraph3}</p>
        <div css={styles.info.showreel}></div>
        <h4>{section2.paragraphs.downTitle}</h4>
        <h1>{section2.paragraphs.downName}</h1>
      </div>
    </section>
  )
}

const styles = {
  mainSection: {
    padding: margins.sectionsX.xl,

    [breakpoint.lg]: {
      padding: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      padding: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      padding: margins.sectionsX.sm,
    },
    [breakpoint.xs]: {
      padding: margins.sectionsX.xs,
    },
  },

  info: {
    p: {
      fontSize: "1.1rem",
    },
    showreel: {
      height: "40rem",
      margin: "3rem 0",
      position: 'relative',
      zIndex: 51,
      [breakpoint.sm]: {
        width: "100%",
      },
    },

    [breakpoint.lg]: {
      p: {
        fontSize: fontSizes.fz4.lg,
      },
    },
    [breakpoint.md]: {
      width: "70%",

      p: {
        fontSize: fontSizes.fz4.md,
      },
    },
    [breakpoint.sm]: {
      width: "100%",

      p: {
        fontSize: fontSizes.fz4.sm,
      },
    },
    [breakpoint.xs]: {
      p: {
        fontSize: fontSizes.fz4.sm,
      },
    },
  },
}
