import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import useWindowSize from '../../hooks/useWindowSize.hook'
import  Logo from '../../components/Logo.component'

import logoSign from '../../assets/brand-logos/_svg/rdb-media.svg'
import { colors, margins, fontSizes, breakpoint, alpha } from '../../cms/style'
import VideoIndex from '../../components/videoBackground/VideoIndex'



const S1 = () => {
  const { width, height } = useWindowSize()
  const backgroundImg = useStaticQuery(graphql`
  query {
    backgroundImg: file(relativePath: { eq: "background-image-rdb.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`)
  const [transformValues, setTransformValues] = useState({ x: -50, y: -50 })

  const _onMouseMove = (e) => {
    const xHalf = width / 2
    const xCof = e.nativeEvent.clientX / xHalf
    const yHalf = height / 2
    const yCof = e.nativeEvent.clientY / yHalf
    const cof = 3

    if (xCof <= 1) {
      if (yCof <= 1) {
        setTransformValues({ x: -50 + xCof * cof, y: -50 + yCof * cof })
      } else {
        setTransformValues({ x: -50 + xCof * cof, y: -50 - yCof * cof })
      }
    } else {
      if (yCof <= 1) {
        setTransformValues({ x: -50 - xCof * cof, y: -50 + yCof * cof })
      } else {
        setTransformValues({ x: -50 - xCof * cof, y: -50 - yCof * cof })
      }
    }
  }

  return (
    <section css={styles.section} onMouseMove={_onMouseMove}>
      <div css={styles.backgroundImg}>
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: colors.brands['reklama'].concat(alpha[10]),
            transition: 'background-color .5s ease-out'
            // zIndex: 1 
          }}
        />
        <VideoIndex />
      </div>
    </section>
  )
}


const styles = {
  section: {
    display: 'flex',
    position: 'relative',
    height: '100vh',
    width: '100vw',
    zIndex: '50',

    [breakpoint.xs]: {
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
  },

  logo: {
    // width: 80,
    height: 120,
    position: 'absolute',
    top: 50,
    left: margins.sectionsX.xl,
    zIndex: 9999,

    svg: {
      height: '100%'
    },

    [breakpoint.lg]: {
      left: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      left: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      left: margins.sectionsX.sm,
      top: 30,
      height: 100
    },
    [breakpoint.xs]: {
      left: margins.sectionsX.xs,
      top: 30,
      height: 70
    },
  },

  logoSign: {
    position: 'absolute',
    top: '60%',
    left: '50%',
    width: '460px',
    height: '400px',
    transform: 'translate(-50%, -50%)',
    transition: 'transform .3s linear',
    textAlign: 'center',

    svg: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      transition: 'opacity .2s ease-out',
      opacity: 0
    },

    [breakpoint.md]: { width: 250, height: 260 },
    [breakpoint.xs]: { top: '50%', width: 185, height: 192 }
  },

  backgroundImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: '-2',
    backgroundColor: '#fff'
  }
}


export default S1