import PropTypes from 'prop-types'
import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import Logo from '../../components/Logo.component'

import { colors, margins, fontSizes, breakpoint, alpha, fonts } from '../../cms/style'
// import Loader from './Loader'
import logoSign from '../../assets/brand-logos/_svg/rdb-media.svg'

//import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
//import Modal from '@material-ui/core/Modal'
import VideoVimeo from './VideoVimeo'

// const TextContainer = lazy(() => import('./TextContainer'))
// const PlayCircleOutlineIcon = lazy(() => import('@material-ui/icons/PlayCircleOutline'))
// const Modal = lazy(() => import('@material-ui/core/Modal'))
// const VideoVimeo = lazy(() => import('./VideoVimeo'))

class VideoIndex extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
  }

  static defaultProps = {
    id: 'elZ6YJu_HgA',
  }

  constructor(props) {
    super(props)
    this.state = { openPlayer: false }
  }

  componentDidMount = () => {
    // On mount, check to see if the API script is already loaded
    if (!window.YT) {
      // If not, load the script asynchronously
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.loadVideo

      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    } else {
      // If script is already there, load the video directly
      this.loadVideo(this.state.openPlayer)
    }
  }

  loadVideo = () => {
    const { id } = this.props
    // the Player object is created uniquely based on the id in props
    this.player = new window.YT.Player(`youtube-player-${id}`, {
      videoId: id,
      width: 1920,
      height: 1080,
      vq: 'hd2160',
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange,
      },
      playerVars: {
        autoplay: 1,
        controls: 0,
        disablekb: 0,
        playsinline: 1,
        fs: 0,
        loop: 1,
        modestbranding: 1,
        rel: 0,
        mute: 1,
        muted: 1,
        autohide: 0,
        playlist: id,
      },
    })
  }

  onPlayerReady = event => {
    // this.player.setVolume(100)
    event.target.setPlaybackQuality('hd2160')
    event.target.playVideo()
  }

  onPlayerStateChange = event => {
    if (event.data === window.YT.PlayerState.BUFFERING) {
      event.target.setPlaybackQuality('hd2160')
      // console.log('buffering')
    }

    // if (this.state.openPlayer) {
    //   event.target.stopVideo()
    // }

    // console.log(this.player)
  }

  render = () => {
    const { id } = this.props
    return (
      <div css={style.container} className="video-background">
        <div id={`youtube-player-${id}`} css={style.video} />
        <div
          onClick={() => this.setState({ openPlayer: true })}
          onKeyPress={() => this.setState({ openPlayer: true })}
          role="button"
          tabIndex="0">
          <div extraCSS={style.openPlayerButton}>
            <div css={style.logo}>
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div css={style.logoSign} >
              <img src={logoSign} alt='reklama dla biznesu' />
            </div>
          </div>
        </div>
        {/* <Suspense fallback={<Loader />}> */}
        {/* </Suspense> */}
      </div>
    )
  }
}

const style = {
  logo: {
    // width: 80,
    height: 120,
    position: 'absolute',
    top: 50,
    left: margins.sectionsX.xl,
    zIndex: 9999,

    svg: {
      height: '100%'
    },

    [breakpoint.lg]: {
      left: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      left: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      left: margins.sectionsX.sm,
      top: 30,
      height: 100
    },
    [breakpoint.xs]: {
      left: margins.sectionsX.xs,
      top: 30,
      height: 70
    },
  },

  logoSign: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    width: '40rem',
    transform: 'translate(-50%, -50%)',
    transition: 'transform .3s linear',
    textAlign: 'center',
    zIndex: '9999',
    svg: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      transition: 'opacity .2s ease-out',
      opacity: 0,
      fill: '#fff',
    },

  },

  container: {
    overflow: 'hidden',
    position: 'relative',
    display: 'inline-block',
    width: '100vw',
    height: 'calc(100vh + 2px)',
  },
  video: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    zIndex: -1,
    height: '100vh',
    minHeight: '100%',
    // desktop
    '@media (min-aspect-ratio: 16/9)': {
      height: 'calc(900vw / 16)',
      width: '100vw',
    },
    // mobile
    '@media (max-aspect-ratio: 16/9)': {
      height: '100vh',
      width: 'calc(1600vh / 9)',
    },
    pointerEvents: 'none',
  },
  openPlayerButton: {
    width: 275,
    fontSize: 0.875 + 'rem',
    color: 'white',
    backgroundColor: 'transparent',
    border: '2px solid rgba(255,255,255,0.5)',
    position: 'absolute',
    bottom: '5%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&:hover': {
      borderColor: 'transparent',
    },
  },
  modal: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // width: '100vw',
    // height: '100vh',
    maxHeight: '100vh',
    maxWidth: '100vw',
  },
}

export default VideoIndex
